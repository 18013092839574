.padding-10-0 {
    padding: 10px 0;
}
textarea {
    max-width: 100%;
    border: 1px solid #ccc; }

#newreport .inputs label {
    display: inline-block;
    vertical-align: top;
    width: 140px;
}

#newreport .hours {
    display: inline-block;
}

@media screen and (max-width: 767px) {
    #call_detail > label {
        display: block;
    }

    form input {
        border: solid 1px #ddd;
    }

    .table textarea {
        height: 80px !important;
    }
}